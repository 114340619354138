class OWBBookingServices {
    static instance;

    static getInstance() {
        if (!OWBBookingServices.instance) {
            OWBBookingServices.instance = new OWBBookingServices();
        }
        return OWBBookingServices.instance;
    }

    constructor() {
        this.changeServiceSelect('#booking-service-select, form.cart [name="booking_service_jumper"]');
        if ($('#booking-service-select').length > 0) {
            $('#booking-service-select, form.cart [name="booking_service_jumper"]').on('change', (event) => {
                this.changeServiceSelect(event.currentTarget);
            });
        }
    }

    changeServiceSelect(input) {
        let $form = $(input).closest('form');
        let service = $form.find('#booking-service-select option:selected').data('price');
        let quantity = $form.find('[name="booking_service_jumper"]').val();
        let price = $form.find('#owb-booking-result').data('price');
        this.renderHtml(price, quantity, service);
    }

    renderHtml(price, quantity, service) {
        let total = Number(this.getNumber(price)) * Number(quantity);
        let html = `${quantity} x ${price}`;
        if (typeof service !== 'undefined' && service) {
            html += ` + ${service}`;
            total += +Number(this.getNumber(service));
        }
        $.ajax({
            method: "POST",
            url: wc_add_to_cart_params.ajax_url,
            data: {
                price: total,
                action: 'owb_format_price'
            },
            beforeSend: () => {
                $('#booking_total_price').val(total);
            },
            success: (response) => {
                if(response.status){
                    html += ` = ${response.result}`;
                    $('#owb-booking-result').html(html);
                }
            }
        });

    }

    getNumber(str) {
        return str.replace(/([^\d|^\.])*/g, '');
    }
}
