class OWBDateTimePicker{
    static instance;

    static getInstance() {
        if (!OWBDateTimePicker.instance) {
            OWBDateTimePicker.instance = new OWBDateTimePicker();
        }
        return OWBDateTimePicker.instance;
    }

    constructor(){
        $('[date-picker]').datetimepicker({
            timepicker:false,
            mask:true,
            format:'d/m/Y',
            minDate: new Date(),
            scrollMonth : false,
            scrollInput : false
        })

        $('[time-picker]').datetimepicker({
            datepicker:false,
            mask:true,
            format:'H:i',
            scrollMonth : false,
            scrollInput : false
        })
    }
}