class OWBJs {
    constructor() {
        OWBCart.getInstance();
        OWBBookingServices.getInstance();
        OWBDateTimePicker.getInstance();
    }
}

$(document).ready(() => {
    new OWBJs();
});
